import React, { useEffect, useState } from 'react'
import './footer.css'

export default function Footer() {
    const [theme, setTheme] = useState('dark-theme');

    const toggle = (option) => {
        setTheme(option + '-theme');
    }

    useEffect(() => {
        document.body.className = theme;
    }, [theme]);

    const scrollToTop = () => {
        const element = document.getElementById('home');
        element.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className='footer'>
            <div className='footer-name'>
                <p><span>© </span>Aman Makhija</p>
            </div>
            <div className='toggle'>
                <select onChange={(e) => toggle(e.target.value)}>
                    <option value='dark'>Dark</option>
                    <option value='light'>Light</option>
                </select>
            </div>
            <div className='footer-social'>
                <a className='linkedin' href='https://www.linkedin.com/in/aman-makhija/' target='_blank' rel='noreferrer'>
                    <i className="fa fa-linkedin"></i>
                </a>
                <a className='github' href='https://www.github.com/amanmakhija/' target='_blank' rel='noreferrer'>
                    <i className="fa fa-github"></i>
                </a>
                <a className='instagram' href='https://www.instagram.com/amn.makhija/' target='_blank' rel='noreferrer'>
                    <i className="fa fa-instagram"></i>
                </a>
                <a className='twitter' href='https://www.twitter.com/amnmakhija/' target='_blank' rel='noreferrer'>
                    <i className="fa fa-twitter"></i>
                </a>
            </div>
        </div>
    )
}
