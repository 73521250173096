import React from 'react'
import './contact.css'

export default function Contact() {
    return (
        <div id='contact' className='contact'>
            <div className='contact-heading'>
                <p>Need Help? Contact Me!</p>
            </div>
            <div className='contact-subheading'>
                <p>If you have a project that you want to get started, think you need my help with something or just fancy saying hey, then get in touch.</p>
            </div>
            <div className='contact-btn-container'>
                <a href='mailto:amanmakhija2205@gmail.com' className='contact-btn'><i className='fa fa-envelope'></i> Message Me</a>
            </div>
        </div>
    )
}
