import React from 'react'
import './header.css'

export default function Header() {
    const scrollToPage = (e) => {
        const page = e.target.innerText.toLowerCase();
        const element = document.getElementById(page) || document.getElementById('home');
        element.scrollIntoView({ behavior: 'smooth' });
        const navbar = document.querySelector('.navbar');
        const menu = document.querySelector('.navbar-options');
        navbar.classList.remove('navbar-open');
        menu.classList.remove('open');
    }

    const openMenu = () => {
        const menu = document.querySelector('.navbar-options');
        const navbar = document.querySelector('.navbar');
        if (menu.classList.contains('open')) {
            console.log('closed');
            navbar.classList.remove('navbar-open');
            menu.classList.remove('open');
        } else {
            console.log('opened');
            navbar.classList.add('navbar-open');
            menu.classList.add('open');
        }
    }

    return (
        <div className='navbar'>
            <div className='navbar-logo'>
                <span>Aman.</span>
            </div>
            <div onClick={() => openMenu()} className='hamburger'>
                <span>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                </span>
            </div>
            <div className='navbar-options'>
                <ul>
                    <li onClick={(e) => scrollToPage(e)}>Home</li>
                    <li onClick={(e) => scrollToPage(e)}>About</li>
                    <li onClick={(e) => scrollToPage(e)}>Projects</li>
                    <li onClick={(e) => scrollToPage(e)}>Contact</li>
                </ul>
            </div>
        </div>
    )
}
