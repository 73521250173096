import React, { useState, useEffect } from 'react'
import './home.css'

export default function Home() {
    const [showButton, setShowButton] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > window.innerHeight) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        const element = document.getElementById('home');
        element.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div id='home' className='home'>
            <div className="area">
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            <div className='home-main'>
                <h1>I'm Aman.</h1>
                <p>a web developer.</p>
            </div>
            {showButton && (
                <div className='top-btn'>
                    <span onClick={scrollToTop} className='scroll-to-top'>
                        <i className="fa fa-arrow-up"></i>
                    </span>
                </div>
            )}
        </div>
    )
}
