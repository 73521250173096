import React, { useState } from 'react'
import './about.css'

export default function About() {
    // eslint-disable-next-line
    const [design, setDesign] = useState(['fa fa-eye', 'fa fa-pencil', 'fa fa-paint-brush']);
    const [designIcon, setDesignIcon] = useState(design[0]);
    // eslint-disable-next-line
    const [dev, setDev] = useState(['fa fa-mobile', 'fa fa-laptop', 'fa fa-desktop']);
    const [devIcon, setDevIcon] = useState(dev[0]);

    const handleDesign = () => {
        var i;
        if (design[0] === designIcon) {
            i = 1;
        } else if (design[1] === designIcon) {
            i = 2;
        } else if (design[2] === designIcon) {
            i = 0;
        }
        setDesignIcon(design[i]);
    }

    const handleDev = () => {
        setTimeout(() => {
            var i;
            if (dev[0] === devIcon) {
                i = 1;
            } else if (dev[1] === devIcon) {
                i = 2;
            } else if (dev[2] === devIcon) {
                i = 0;
            }
            setDevIcon(dev[i]);
        }, 200);
    }

    setTimeout(() => {
        handleDev();
        handleDesign();
    }, 5000);

    return (
        <div id='about' className='about'>
            <div className='about-main'>
                <p>Hi.</p>
                <p>I'm a Full Stack Developer specialized in MERN Stack. I'm passionate about developing & designing, and love to create for web and mobile devices.</p>
            </div>
            <hr />
            <div className='about-main-2'>
                <p>What I can do.</p>
                <div className='services'>
                    <div className='logo'>
                        <i className={designIcon}></i>
                    </div>
                    <hr className='services-hr' />
                    <div className='content'>
                        <p><span className='content-heading'>Design</span></p>
                        <p className='content-body'>I prefer to keep things simple. My objectives are to concentrate on typography, content, and communicating the message that you want to portray.</p>
                    </div>
                </div>
                <div className='services'>
                    <div className='content'>
                        <p><span className='content-heading'>Development</span></p>
                        <p className='content-body'>I'm a developer, so I know how to build your website so that it works on all devices with the most up-to-date technologies.</p>
                    </div>
                    <hr className='services-hr' />
                    <div className='logo'>
                        <i className={devIcon}></i>
                    </div>
                </div>
            </div>
        </div>
    )
}
