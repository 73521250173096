import React, { useEffect, useState } from 'react'
import './projects.css'

export default function Projects() {
    const [myProjects, setMyProjects] = useState([]);

    const renderList = () => {
        return myProjects.map(p => (
            <div className='project' key={p.n}>
                <div className='project-image' style={{ background: `url(${p.img})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}></div>
                <div className='project-description'>
                    <p>{p.description}</p>
                </div>
                <a className='project-btn' target={p.link !== '#projects' ? '_blank' : '_parent'} rel='noreferrer' href={p.link}>View Live Project ({p.name})</a>
            </div>
        ));
    };

    return (
        <div id='projects' className='projects'>
            <div className='project-heading'>
                <h1>Projects</h1>
            </div>
            <div className='project-container'>
                {myProjects.length === 0 ? <p className='empty'>New projects coming soon... 🤠</p> : renderList()}
            </div>
        </div>
    )
}
